@import './illustrations/sass/main';

.vi__container {
  width: 100%;
}

.character {
  border: none;
  font-size: 20px;
  
  color: #272729;
  background-color: transparent;
  border: unset;
  border-bottom: 3px solid #D4D7DC;
}

.character--selected {
  border-color: #6078EA;
  outline: none;
}
