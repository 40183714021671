@font-face {
  font-family: 'Hellix';
  src: url('./../../fonts/Hellix-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 500;
  src: url('./../../fonts/Hellix-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 600;
  src: url('./../../fonts/Hellix-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 700;
  src: url('./../../fonts/Hellix-Bold.otf') format('opentype');
}

* {
  font-family: 'Hellix' !important;
}
