@keyframes progressIn {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes progressOut {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.hide {
  width: 0%;
}

.show {
  width: 100%;
}
